// components/ImpactSection.js
import React from 'react';
import { Heart, Globe, DollarSign } from 'lucide-react';

const ImpactSection = () => (
  <section className="py-20 bg-soft-white">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-16 text-charcoal">Our Impact</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        <div className="text-center bg-white p-8 rounded-lg shadow-md transform hover:-translate-y-1 transition duration-300">
          <Heart size={48} className="mx-auto mb-6 text-terra-cotta" />
          <h3 className="text-2xl font-semibold mb-2 text-blue-800">10,000+</h3>
          <p className="text-gray-600">People Supported</p>
        </div>
        <div className="text-center bg-white p-8 rounded-lg shadow-md transform hover:-translate-y-1 transition duration-300">
          <Globe size={48} className="mx-auto mb-6 text-terra-cotta" />
          <h3 className="text-2xl font-semibold mb-2 text-blue-800">50+</h3>
          <p className="text-gray-600">Communities Reached</p>
        </div>
        <div className="text-center bg-white p-8 rounded-lg shadow-md transform hover:-translate-y-1 transition duration-300">
          <DollarSign size={48} className="mx-auto mb-6 text-terra-cotta" />
          <h3 className="text-2xl font-semibold mb-2 text-blue-800">$500,000+</h3>
          <p className="text-gray-600">Financial Aid Provided</p>
        </div>
      </div>
    </div>
  </section>
);

export default ImpactSection;