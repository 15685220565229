// pages/ProgramsPage.js
import React from 'react';

const ProgramsPage = () => (
  <div className="container mx-auto px-4 py-12">
    <h1 className="text-4xl font-bold mb-8 text-blue-800">Our Programs</h1>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="bg-soft-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-blue-700">Food Distribution</h2>
        <p className="mb-4">Our food distribution program aims to combat hunger and malnutrition in vulnerable communities. We provide:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Nutritious meal packages</li>
          <li>Emergency food supplies</li>
          <li>Support for local food banks</li>
        </ul>
        <p>Through partnerships with local farmers and food suppliers, we ensure a steady supply of fresh, nutritious food to those in need.</p>
      </div>
      <div className="bg-soft-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-blue-700">Clothing Drive</h2>
        <p className="mb-4">Our clothing drive program ensures that individuals and families have access to clean, appropriate clothing. We focus on:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Seasonal clothing collections</li>
          <li>School uniform distributions</li>
          <li>Professional attire for job seekers</li>
        </ul>
        <p>We work with local businesses and community members to collect and distribute clothing to those who need it most.</p>
      </div>
      <div className="bg-soft-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-blue-700">Financial Assistance</h2>
        <p className="mb-4">Our financial assistance program helps individuals and families overcome economic hardships. We offer:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Emergency financial aid</li>
          <li>Microloans for small businesses</li>
          <li>Financial literacy workshops</li>
        </ul>
        <p>Through these initiatives, we aim to promote financial stability and independence in the communities we serve.</p>
      </div>
      <div className="bg-soft-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-blue-700">Education Support</h2>
        <p className="mb-4">Our education support program focuses on improving access to quality education. We provide:</p>
        <ul className="list-disc list-inside mb-4">
          <li>School supplies and textbooks</li>
          <li>Scholarships for promising students</li>
          <li>After-school tutoring programs</li>
        </ul>
        <p>By investing in education, we're helping to build a brighter future for the next generation.</p>
      </div>
    </div>
  </div>
);

export default ProgramsPage;