
// components/Footer.js
import React from 'react';
import { Users } from 'lucide-react';
import Logo from './Logo';

const Footer = () => (
  <footer className="bg-charcoal text-white py-12">
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
        <div>
          <div className="flex items-center space-x-2 mb-4">
            <Logo />
            <h3 className="text-xl font-semibold">Charivale</h3>
          </div>
          <p className="text-gray-400">Empowering communities in Nigeria through compassion and support.</p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
          <ul className="space-y-2">
            <li><a href="/about" className="text-gray-400 hover:text-white transition duration-300">About Us</a></li>
            <li><a href="/programs" className="text-gray-400 hover:text-white transition duration-300">Our Programs</a></li>
            <li><a href="/donate" className="text-gray-400 hover:text-white transition duration-300">Donate</a></li>
            <li><a href="contact" className="text-gray-400 hover:text-white transition duration-300">Contact Us</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Contact</h3>
          <p className="text-gray-400">123 Charity Street, Lagos, Nigeria</p>
          <p className="text-gray-400">Email: info@charivale.org</p>
          <p className="text-gray-400">Phone: +234 123 456 7890</p>
        </div>
      </div>
      <div className="mt-12 text-center text-gray-500">
        <p>&copy; 2024 Charivale. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

export default Footer;
