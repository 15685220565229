// components/Logo.js
import React from 'react';

const Logo = () => (
  <svg className="w-10 h-10" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#1E40AF"/>
    <path d="M20 10C14.48 10 10 14.48 10 20C10 25.52 14.48 30 20 30C25.52 30 30 25.52 30 20C30 14.48 25.52 10 20 10ZM16 25.5L11.5 21L13.66 18.84L16 21.16L26.34 10.82L28.5 13L16 25.5Z" fill="#F7F7F7"/>
  </svg>
);

export default Logo;