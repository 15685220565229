// components/TestimonialSection.js
import React from 'react';

const TestimonialSection = () => (
  <section className="py-20 bg-blue-800 text-white">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-3xl font-bold mb-12">Voices of Impact</h2>
      <div className="max-w-3xl mx-auto">
        <blockquote className="text-2xl italic mb-8 leading-relaxed">
          "Charivale's support has been a lifeline for our community. Their generosity has brought hope and relief to so many families."
        </blockquote>
        <p className="font-semibold text-gold">- Nneka A., Community Leader in Anambra</p>
      </div>
    </div>
  </section>
);

export default TestimonialSection;