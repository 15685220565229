// components/ProgramsSection.js
import React from 'react';

const ProgramsSection = () => (
  <section className="py-20 bg-white">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-16 text-charcoal">Our Programs</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        <div className="bg-soft-white p-8 rounded-lg shadow-md border border-gray-200">
          <h3 className="text-xl font-semibold mb-4 text-blue-700">Food Distribution</h3>
          <p className="text-gray-700">We provide nutritious meals and food packages to families facing hunger and food insecurity.</p>
        </div>
        <div className="bg-soft-white p-8 rounded-lg shadow-md border border-gray-200">
          <h3 className="text-xl font-semibold mb-4 text-blue-700">Clothing Drive</h3>
          <p className="text-gray-700">Our clothing program ensures that individuals and families have access to clean, appropriate clothing.</p>
        </div>
        <div className="bg-soft-white p-8 rounded-lg shadow-md border border-gray-200">
          <h3 className="text-xl font-semibold mb-4 text-blue-700">Financial Assistance</h3>
          <p className="text-gray-700">We offer financial aid and support to help individuals and families overcome economic hardships.</p>
        </div>
      </div>
    </div>
  </section>
);

export default ProgramsSection;