// pages/AboutPage.js
import React from 'react';

const AboutPage = () => (
  <div className="container mx-auto px-4 py-12">
    <h1 className="text-4xl font-bold mb-8 text-blue-800">About Charivale</h1>
    <p className="text-lg mb-6">
      Charivale is a non-profit organization dedicated to providing aid and support to needy communities in Nigeria, West Africa. Our mission is to empower individuals and families through sustainable assistance programs.
    </p>
    <p className="text-lg mb-6">
      Founded in 2010, we have been working tirelessly to address the pressing needs of vulnerable populations in Nigeria. Our team of dedicated volunteers and staff members work hand-in-hand with local communities to identify needs and implement effective solutions.
    </p>
    <h2 className="text-2xl font-bold mb-4 text-blue-700">Our Values</h2>
    <ul className="list-disc list-inside mb-6">
      <li>Compassion: We approach our work with empathy and understanding.</li>
      <li>Integrity: We maintain the highest ethical standards in all our operations.</li>
      <li>Empowerment: We strive to help communities become self-sufficient.</li>
      <li>Collaboration: We believe in the power of partnerships to create lasting change.</li>
    </ul>
    <p className="text-lg">
      Join us in our mission to create a brighter future for communities in Nigeria. Together, we can make a difference.
    </p>
  </div>
);

export default AboutPage;