// pages/DonatePage.js
import React, { useState } from 'react';

const DonatePage = () => {
  const [donationAmount, setDonationAmount] = useState('');

  const handleDonation = (e) => {
    e.preventDefault();
    // Here you would typically integrate with a payment processor
    alert(`Thank you for your donation of $${donationAmount}!`);
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8 text-blue-800">Donate to Charivale</h1>
      <p className="text-lg mb-6">Your generosity can make a real difference in the lives of those we serve. Every donation, no matter the size, helps us continue our mission of empowering communities in Nigeria.</p>
      
      <form onSubmit={handleDonation} className="max-w-md mx-auto">
        <div className="mb-4">
          <label htmlFor="amount" className="block text-gray-700 font-bold mb-2">Donation Amount (USD)</label>
          <input 
            type="number" 
            id="amount" 
            value={donationAmount} 
            onChange={(e) => setDonationAmount(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter amount"
            required
          />
        </div>
        <button type="submit" className="bg-terra-cotta hover:bg-terra-cotta-light text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">
          Donate Now
        </button>
      </form>
      
      <div className="mt-12">
        <h2 className="text-2xl font-bold mb-4 text-blue-700">Other Ways to Support</h2>
        <ul className="list-disc list-inside">
          <li>Volunteer your time and skills</li>
          <li>Organize a fundraising event in your community</li>
          <li>Spread awareness about our cause on social media</li>
          <li>Partner with us as a corporate sponsor</li>
        </ul>
      </div>
    </div>
  );
};

export default DonatePage;