// pages/HomePage.js
import React from 'react';
import Hero from '../components/Hero';
import ImpactSection from '../components/ImpactSection';
import ProgramsSection from '../components/ProgramsSection';
import TestimonialSection from '../components/TestimonialSection';

const HomePage = () => (
  <div>
    <Hero />
    <ImpactSection />
    <ProgramsSection />
    <TestimonialSection />
  </div>
);

export default HomePage;