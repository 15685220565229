import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'lucide-react';
import Logo from './Logo';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-white text-gray-800 p-4 shadow-sm">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Logo />
          <h1 className="text-2xl font-bold text-blue-800">Charivale</h1>
        </div>
        <nav className="hidden md:block">
          <ul className="flex space-x-6">
            <li><Link to="/" className="hover:text-blue-600 transition duration-300">Home</Link></li>
            <li><Link to="/about" className="hover:text-blue-600 transition duration-300">About</Link></li>
            <li><Link to="/programs" className="hover:text-blue-600 transition duration-300">Programs</Link></li>
            <li><Link to="/donate" className="hover:text-blue-600 transition duration-300">Donate</Link></li>
            <li><Link to="/contact" className="hover:text-blue-600 transition duration-300">Contact</Link></li>
          </ul>
        </nav>
        <button className="md:hidden text-gray-800" onClick={toggleMenu}>
          <Menu size={24} />
        </button>
      </div>
      {isMenuOpen && (
        <nav className="md:hidden mt-2">
          <ul className="flex flex-col space-y-2">
            <li><Link to="/" className="hover:text-blue-600 transition duration-300" onClick={closeMenu}>Home</Link></li>
            <li><Link to="/about" className="hover:text-blue-600 transition duration-300" onClick={closeMenu}>About</Link></li>
            <li><Link to="/programs" className="hover:text-blue-600 transition duration-300" onClick={closeMenu}>Programs</Link></li>
            <li><Link to="/donate" className="hover:text-blue-600 transition duration-300" onClick={closeMenu}>Donate</Link></li>
            <li><Link to="/contact" className="hover:text-blue-600 transition duration-300" onClick={closeMenu}>Contact</Link></li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
