// components/Hero.js
import React from 'react';

const Hero = () => (
  <section className="bg-gradient-to-r from-blue-700 to-blue-800 text-white py-24">
    <div className="container mx-auto text-center px-4">
      <h2 className="text-4xl md:text-5xl font-bold mb-6 leading-tight">Become a Helping Hand</h2>
      <p className="text-xl mb-10 max-w-2xl mx-auto">Providing food, clothing, and financial aid to those in need, creating lasting change in Nigeria, West Africa.</p>
      <button className="bg-terra-cotta text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-terra-cotta-light transition duration-300 shadow-lg">Donate Now</button>
    </div>
  </section>
);

export default Hero;
